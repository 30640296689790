import { FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Dropdown, Spinner } from 'elements';

import {
  Wrapper,
  Modal,
  Head,
  HeadTitle,
  Hr,
  CloseBtn,
  Background,
  Header,
  HeaderCol,
  Title,
  Text,
  Body,
  SpinnerWrapper,
  ChartArea,
  Legend,
  DropdownToggler
} from "./styled";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

// @ts-ignore
// import ChevronRightIcon from "assets/images/journeys/chevron-right.svg";

import LegendItem from "../LegendItem";
import DonutChart from "../DonutChart";
import CustomDatePicker from "../CustomDatePicker";

import { ListTimeDistributionState } from "Redux/APM/ListTimeDistribution/types";

import { listTimeDistribution } from "Redux/APM/ListTimeDistribution/action";

const items = [
  {
    key: 'week',
    element: 'This Week',
  },
  {
    key: 'month',
    element: 'This Month',
  },
  {
    key: 'year',
    element: 'This Year',
  }
];

interface IProps {
  active: boolean;
  onClose: () => void;
}

const TimeAllocationPopup: FC<IProps> = ({ active, onClose }) => {
  const dispatch = useDispatch();

  const $body = useRef<HTMLDivElement>(null);

  const [isRendered, setIsRendered] = useState(false);
  const [isActiveState, setIsActiveState] = useState(false);

  const [hoverItemId, setHoverItemId] = useState<string | null>(null);
  const [size, setSize] = useState(488);
  const [date, setDate] = useState(items[0]);
  const [dateRange, setDateRange] = useState<any>([moment().startOf('week'), moment().endOf('week')]);

  const { data, loading } = useSelector(
    (state: any) => state.listTimeDistribution as ListTimeDistributionState
  );

  useEffect(() => {
    if (active) {
      setIsRendered(true);
      setTimeout(() => setIsActiveState(true), 50);
    } else {
      setIsActiveState(false);
      setTimeout(() => setIsRendered(false), 500);
    }
  }, [active]);

  useEffect(() => {
    if (!isRendered)
      return;

    const value = date.key as 'week' | 'month' | 'year';

    const startDate = moment().startOf(value).format('YYYY-MM-DD');
    const endDate = moment().endOf(value).format('YYYY-MM-DD');

    const params = {
      start_date: startDate,
      end_date: endDate,
    }

    dispatch(listTimeDistribution(params));
  }, [date, isRendered]);

  useEffect(() => {
    const updateSize = () => {
      if ($body.current) {
        setSize($body.current.offsetHeight);
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  const handleClose = () => {
    onClose();
  }

  const handleHover = (id: string | null) => {
    setHoverItemId(id);
  }

  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
  }

  if (!isRendered) {
    return null;
  }

  return createPortal(
    <Wrapper $active={isActiveState}>
      <Modal $active={isActiveState}>
        <Head>
          <HeadTitle>
            <span>TIME ALLOCATION</span>
          </HeadTitle>

          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>
        </Head>

        <Hr />

        <Header>
          <HeaderCol>
            <Title>Time allocation across areas & Categories</Title>
            <Text>Pie chart visually representing the total time prioritized based on different Areas and Categories.</Text>
          </HeaderCol>

          <CustomDatePicker
            value={dateRange}
            onChange={handleDateRangeChange}
          />

          {/* <Dropdown
            menuItems={items}
            value={date.key}
            secondary
            onChange={(value: any) => {
              setDate(items.find(item => item.key === value.key) || items[0]);
            }}
          >
            <DropdownToggler>
              {date.element || 'Select'}
              <img src={ChevronRightIcon} alt="" />
            </DropdownToggler>
          </Dropdown> */}
        </Header>

        <Hr />

        <Body ref={$body}>
          {loading ? <SpinnerWrapper>
            <Spinner variant="secondary" />  
          </SpinnerWrapper> : null}

          <ChartArea>
            <DonutChart
              data={data}
              size={size}
              onHover={handleHover}
            />
          </ChartArea>

          <Legend>
            {data.map((item) => <LegendItem data={item} active={hoverItemId === item.goalId} key={item.goalId} />)}
          </Legend>
        </Body>
      </Modal>
      <Background />
    </Wrapper>,
    document.getElementById("portal") as HTMLElement
  );
}

export default TimeAllocationPopup;
