import { FC, useRef, useEffect } from 'react';
import styled from "styled-components";
import * as d3 from 'd3';

import { ITimeDistribution } from 'Redux/APM/ListTimeDistribution/types';

interface IProps {
  data: ITimeDistribution[];
  size: number;
  onHover: (id: string | null) => void;
}

const convertMinutesToTimeFormat = (time?: number | number) => {
  if (!time) return "0h";

  const hours = Math.floor(time / 60);
  const mins = time % 60;

  let result = "";

  if (hours) {
    result += `${hours}h`;
  }

  if (mins) {
    if (result) result += " ";
    result += `${mins}m`;
  }

  return result || `0h`;
};

const DonutChart: FC<IProps> = ({ data, size, onHover }) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    drawChart();
  }, [data, size]);

  const drawChart = () => {
    const width = Math.min(size, 480);
    const height = Math.min(size, 480);

    const radius = Math.min(width, height) / 2;
    const gapSize = 1.5 * (Math.PI / 180); // 2px gap size, converted to radians

    // Remove the old SVG
    d3.select(ref.current).selectAll("*").remove();

    const svg = d3.select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    const totalValue = d3.sum(data, d => d.totalTime);
    const totalGapAngle = gapSize * data.length;
    let currentAngle = 0;

    const adjustedData = data.map(d => {
      const proportionOfCircle = (d.totalTime / totalValue) * (2 * Math.PI - totalGapAngle);
      const startAngle = currentAngle;
      const endAngle = currentAngle + proportionOfCircle;
      currentAngle = endAngle + gapSize;

      return {
        id: d.goalId,
        value: d.totalTime,
        color: d.areaColor,
        startAngle: startAngle,
        endAngle: endAngle
      };
    });

    const arc = d3.arc<{ startAngle: number; endAngle: number; color: string; id: string }>()
      .innerRadius(radius * 0.5)
      .outerRadius(radius)
      .startAngle(d => d.startAngle)
      .endAngle(d => d.endAngle);

    svg.selectAll('path')
      .data(adjustedData)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('style', (d: any) => `fill: ${d.color || "#ced0d4"}`)
      
      .on("mouseenter", (_, d) => {
        onHover(d.id);
      })
      .on('mouseleave', () => {
        onHover(null);
      })

    // Draw the transparent circle with a thick gray border
    svg.append("circle")
      .attr("r", radius * 0.5)
      .attr("fill", "transparent")
      .attr("stroke", "#FBFBFB")
      .attr("stroke-width", 24);

    // Add the label in the center
    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("y", "-10")
      .attr("fill", "#404040")
      .style("font-size", "16px")
      .style('font-weight', '700')
      .text("Total Allocated Time");

    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("y", "20")
      .attr("fill", "#404040")
      .style("font-size", "20px")
      .style('font-weight', '700')
      .text(convertMinutesToTimeFormat(totalValue));
  };

  return <CustomSvg ref={ref} />;
};

export default DonutChart;

export const CustomSvg = styled.svg`
  position: absolute;

  path {
    fill: #ced0d4;
  }
}`;
