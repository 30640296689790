// @ts-ignore
import { getTimeDistributionData } from "../apm-apis";

import Notify from "utils/notify";
import { ActionTypes } from "./types";

export const listTimeDistribution = (params: any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await getTimeDistributionData(params);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};
