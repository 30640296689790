import { FC } from 'react';
import { ITimeDistribution } from 'Redux/APM/ListTimeDistribution/types';
import {
  Wrapper,
  Col,
  Details,
  Title,
  Text,
  Time,
  Label
} from './styled';

interface IProps {
  data: ITimeDistribution
  active: boolean
}

const convertMinutesToTimeFormat = (time?: number | number) => {
  if (!time) return "0h";

  const hours = Math.floor(time / 60);
  const mins = time % 60;

  let result = "";

  if (hours) {
    result += `${hours}h`;
  }

  if (mins) {
    if (result) result += " ";
    result += `${mins}m`;
  }

  return result || `0h`;
};

const LegendItem: FC<IProps> = ({ data, active }) => {
  return (
    <Wrapper $active={active}>
      <Col>
        <Label style={{ backgroundColor: data.areaColor || "#ced0d4" }} />

        <Details>
          <Title>{data.categoryName}</Title>
          <Text>{data.areaName}</Text>
        </Details>
      </Col>

      <Time>{convertMinutesToTimeFormat(data.totalTime)}</Time>
      {/* <Time><span>15</span>hr <span>20</span>m</Time> */}
    </Wrapper>
  )
}

export default LegendItem;